import { EVENTS_22_23, EVENTS_23_24, EVENTS_24_25 } from 'assets/events-page/past-events/index';
import booklet from 'assets/events-page/booklet.pdf';

const PAST_EVENTS = {
	'2024/2025': [
		{
			image: EVENTS_24_25[10],
			title: 'GALA(LAND)',
			date: 'April 4, 2025',
			loc: 'AMS Nest Great Hall South',
			button: {
				name: 'View Photos',
				link: 'https://drive.google.com/drive/folders/1rJ-WHsjfbpb7My9VEY5GdAgOS_NLS7zz?usp=sharing',
			},
			recap: 'https://www.instagram.com/reel/DIPz4EmSqE5/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[9],
			title: 'Annual General Meeting',
			date: 'March 27, 2025',
			loc: 'Asian Centre',
			recap: 'https://www.instagram.com/reel/DIILf31pXN6/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[8],
			title: 'Core Hiring',
			date: 'March 19, 2025',
			loc: 'Register Via Link',
			recap: 'https://www.instagram.com/p/DHcrCj_O_JT/?utm_source=ig_web_copy_link',
		},
		{
			image: EVENTS_24_25[7],
			title: 'Indomie Olympics',
			date: 'February 28, 2025',
			loc: 'AMS Nest Lower Atrium',
			button: {
				name: 'View Photos',
				link: 'https://drive.google.com/drive/folders/12MkRif6AXtwpxKqThxAb8idFmshVdD3T',
			},
			recap: 'https://www.instagram.com/reel/DG1xlh1vMct/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[6],
			title: 'La Nusa',
			date: 'January 31, 2025',
			loc: '455 Granville St.',
			button: {
				name: 'View Photos',
				link: 'https://drive.google.com/drive/folders/1NyysJqqihtPAOrNluPqZ5XwqnHZld7MT',
			},
			recap: 'https://www.instagram.com/reel/DFy4rDRJu3I/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[5],
			title: 'Exam Care Package',
			date: 'December 3-4, 2024',
			loc: 'AMS Nest',
			recap: 'https://www.instagram.com/p/DDAqavnzDmP/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[4],
			title: 'Liwetan',
			date: 'November 8, 2024',
			loc: 'Consulate General of Indonesia (KJRI)',
			recap: 'https://www.instagram.com/reel/DCVcy_9vvyE/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[3],
			title: 'Fall Hiring',
			date: 'Tuesday, October 8, 2024',
			loc: 'Submit Via Email',
			recap: 'https://www.instagram.com/p/DAmZz6US8jJ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[2],
			title: 'SOTO: Sobat Tongkrongan',
			date: 'September 27, 2024',
			loc: 'AMS Nest Great Hall South',
			recap: 'https://www.instagram.com/reel/DApMfXiPJ4D/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[1],
			title: 'UBUD: UBC Udah Dekat',
			date: 'August 2, 2024',
			loc: 'Online on Zoom',
			button: {
				name: 'Download Booklet',
				link: booklet,
			},
			recap: 'https://www.instagram.com/p/C9099p8yHES/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_24_25[0],
			title: 'Summer Hiring',
			date: 'July 1, 2024',
			loc: 'Submit Via Email',
			recap: 'https://www.instagram.com/p/C8qaD8GyChl/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
	],
	'2023/2024': [
		{
			image: EVENTS_23_24[13],
			title: 'GALA(U)',
			date: 'April 8, 2024',
			loc: 'AMS Nest Great Hall South',
			recap: 'https://www.instagram.com/reel/C6aP_r4LsVn/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[12],
			title: 'LA NUSA',
			date: 'March 23, 2024',
			loc: 'AURA Nightclub (Downtown)',
			recap: 'https://www.instagram.com/reel/C4o9l-4rWUY/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[11],
			title: "GISAU's Annual General Meeting",
			date: 'March 22, 2024',
			loc: 'AMS Nest Room 2306/2309',
			recap: 'https://www.instagram.com/reel/C49sGA9rn--/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[10],
			title: 'GISAU Core 2024/25 Hiring',
			date: 'March 14, 2024',
			loc: 'Submit Via Email',
			recap: 'https://www.instagram.com/p/C4HhHXUr24E/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[9],
			title: 'Indomie-T Me At The Finish Line',
			date: 'March 1, 2024',
			loc: 'Nest Lower Atrium',
			recap: 'https://www.instagram.com/reel/C4GqttFy86U/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[8],
			title: 'Bermain Bersama GISAU',
			date: 'February 9-11, 2024',
			loc: 'Online | Metrotown',
			recap: 'https://www.instagram.com/p/C2qxTBdLR5a/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[7],
			title: 'Exam Care Package',
			date: 'December 5, 2023',
			loc: 'AMS Nest',
			recap: 'https://www.instagram.com/p/C0NgzHlLzDQ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[6],
			title: 'Study Cafe',
			date: 'December 6, 2023',
			loc: 'AMS Lev Bukhman (Bird Nest)',
			recap: 'https://www.instagram.com/p/C0LEQ9ILcy8/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[5],
			title: 'Malem Mingguan',
			date: 'November 18, 2023',
			loc: 'Consulate General of Indonesia (KJRI)',
			recap: 'https://www.instagram.com/p/CzcvIo7rczw/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[4],
			title: 'Rumah Hantu',
			date: 'October 27, 2023',
			loc: 'AMS Nest Room 2314',
			recap: 'https://www.instagram.com/reel/Cyrw2z4LUpq/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[3],
			title: 'Fall Executive Hiring',
			date: 'October 6, 2023',
			loc: 'Submit Via Email',
			recap: 'https://www.instagram.com/p/CxofgIKP6HX/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[2],
			title: 'SOTO: Sobat Tongkrongan',
			date: 'Wed, 4 October 2023 | 6:30PM',
			loc: 'UBC Asian Centre Auditorium',
			recap: 'https://www.instagram.com/reel/CyB9tvbyaJg/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[1],
			title: 'UBUD: UBC Udah Dekat',
			date: 'Friday, August 4 | 8-9:40 PM',
			loc: 'Online on Zoom',
			recap: 'https://www.instagram.com/p/CvEu2buLVV4/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_23_24[0],
			title: 'Summer Hiring',
			date: 'Thursday, June 6 | 11:59PM',
			loc: 'Submit Via Email',
			recap: 'https://www.instagram.com/p/Cs97iKJPAKO/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
	],
	'2022/2023': [
		{
			image: EVENTS_22_23[13],
			title: 'SEA Pit Night',
			date: 'Wednesday, April 8 | 9PM-1AM',
			loc: 'The Pit Pub @ UBC',
			recap: 'https://www.instagram.com/p/CqZZYAtrk9O/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[12],
			title: 'GALA(U) — Gala With GISAU',
			date: 'Friday, March 31 | 6-8PM',
			loc: 'AMS Nest Great Hall South',
			recap: 'https://www.instagram.com/reel/CrLvC05gsx0/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[11],
			title: 'GISAU Annual General Meeting',
			date: 'Friday, March 24 | 6:15PM',
			loc: 'AMS Nest Room 2306/2309',
			recap: 'https://www.instagram.com/p/Cp3jjeQPTu2/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[10],
			title: '2023 GISAU’s Study Cafe',
			date: 'Wednesday, March 15 | 12-6PM',
			loc: 'Abdul Ladha Room 103/104',
			recap: 'https://www.instagram.com/p/CptL0LdPwhB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[9],
			title: 'FIESTA Vancouver',
			date: 'Saturday, March 4 | 4-8PM',
			loc: 'Nikkei National Museum & Cultural Centre',
			recap: 'https://www.instagram.com/reel/Cp02S21DkjC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[8],
			title: 'Indomie-t Me at the Finish Line',
			date: 'Friday, February 17 | 6:30-8:30PM',
			loc: 'AMS Nest Lower Atrium',
			recap: 'https://www.instagram.com/reel/CpYMOZggr6-/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[7],
			title: 'Welcome Back Picnic',
			date: 'Monday, January 30 | 1-3PM',
			loc: 'Lev Bukhman Theatre Lounge @ UBC AMS Nest',
			recap: 'https://www.instagram.com/p/Cn26m8pv7qB/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[6],
			title: '2022 GISAU’s Study Cafe',
			date: 'Tuesday, December 6 | 1-7PM',
			loc: 'Abdul Ladha Room 103/104',
			recap: 'https://www.instagram.com/p/ClsLC2mrpJ2/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[5],
			title: 'Taste of SEA',
			date: 'Monday, November 14 | 6:30-9PM',
			loc: 'Great Hall South, AMS Nest',
			recap: 'https://www.instagram.com/p/Ckw84mprdSC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[4],
			title: 'Rumah Hantu',
			date: 'Friday, November 4 | 1-8:30PM',
			loc: 'AMS Nest Room 2314',
			recap: 'https://www.instagram.com/p/ClHkdo7ypy8/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[3],
			title: 'Liwetan',
			date: 'Saturday, October 15 | 5-7:30PM',
			loc: 'Consulate General of Indonesia',
			recap: 'https://www.instagram.com/p/Cj_5ne8r6z7/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[2],
			title: 'Halte: UBC',
			date: 'Thursday, September 29 | 6-9PM',
			loc: 'AMS Nest Great Hall South',
			recap: 'https://www.instagram.com/p/Cix5-Wuv1UA/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[1],
			title: 'Opt to Know Co-op',
			date: 'Friday, August 26 | 8PM',
			loc: 'via Zoom',
			recap: 'https://www.instagram.com/p/ChliM5zrJLR/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
		{
			image: EVENTS_22_23[0],
			title: 'Independence Day Picnic',
			date: 'Saturday, August 20 | 4:30PM',
			loc: 'Nobel Park @ UBC',
			recap: 'https://www.instagram.com/reel/CinSQg8gOI-/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
		},
	],
};

export default PAST_EVENTS;
